.spin_orphan_sliders .swiper-wrapper{
    margin-left: 30px;
}
#sponsor_page {
    min-height: 100vh;
}
.sponsor_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    filter: brightness(60%);
}
.sponsor_editamount {
    border: none;
}
.btns_wraper_all {
    min-height: 148px;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%
}
.TermsAndCondSpon {
    min-height: 150px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 20px;
}
.termsAndCondText {
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease-in;
}
.termsAndCondText:hover {
    /* color: #c0706b; */
    border-bottom: 2px solid #ee4236;
}
.favoritesIcon {
    height: 50px;
    width: 55px;
}
.spin_orphan_sliders {
    padding: 50px 10px 50px 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 45px;
    width: 100%;
}
.label_to_beSmall {
    min-height: 33px;
    text-align: center;
}

.orphan_card_border{
    background: #000000;
    display: flex;
    justify-content: center;
    height:380px;
    width:200px;
    border-radius: 30px !important;
    transition: all .2s ease-in-out;
    z-index: 4;
}
.orphan_btn_border{
    display: flex;
    justify-content: center;
    width:100%;
    border-radius: 30px !important;
    transition: all .2s ease-in-out;
    overflow: hidden !important;
    z-index: 3;
}

.btn_img_wraper_become_a_spon {
    border-radius: 30px !important;
    padding: 0px;
    position: relative;
    transition: all .2s ease-in-out;
    overflow: hidden !important;
    z-index: 2;
}
.btn_img_wraper_become_a_spon img{
    overflow: hidden !important;
    border-radius: 30px;
    transform-origin: 45% 65%;
    transition: all 1.5s ease;
    z-index: 1;
}

@media screen and (min-width:900px){
    .orphan_card_border:hover { 
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 0px 15px 5px #383838;
}
  .btn_img_wraper_become_a_spon img:hover {
        border-radius: 30px !important;
        transform: scale(1.1);
    }
}
.orphan_sliders button {
    z-index: 1000;
    height: 380px;
    width: 200px;
}

.btn_img_become_a_spon {
    border-radius: 30px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: grayscale(100%);
}

.btn_img_label_become_a_spon {
    display: flex;
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.397);
    padding: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    justify-content: center;
}
.country_sliders {
    width: 100%;
    padding: 40px;
    
}
.second {
    min-height: 388px;
}
.first {
    min-height: 435px;
}
.orphan_sliders_flags img {
    filter: unset
}
.choosen_method_become_a_sposer {
    padding: 20px;
    display: grid;
    grid-template-rows: auto auto;
    align-items: flex-start;
    gap: 20px;
}
.years_become_a_sposer_title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.years_become_a_sposer_title  label {
    font-size: 22px;
    color: gray;
}
.years_become_a_sposer {
    padding: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: -2px 1px 8px #8888888f;
    border-radius: 20px;
    transition: all .3s ease-in-out;
}

@media screen and (min-width:900px){
    .years_become_a_sposer:hover { 
        transform: scale(1.03);
        box-shadow: 0px 0px 15px 1px #0f0f0f;
  }
}

.years_amount_become_a_sposer {
    gap: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.amount_years_slider {
    height: 100% !important;
}
.amount_years_slider label {
    width: 100%;
    text-align: center;
}
.btn_for_expand {
    color: #ee4236;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
}
.payment_date {
    border: none;
    padding: 15px 40px 20px 40px;
    background-color: white;
    box-shadow: 2px 0px 8px #8888884f;
    border-radius: 30px;
    font-weight: bold;
    color: gray;
}
.check_out_payment_method_btns {
    padding: 20px;
    display: flex;
    align-items: center;
}
.shadowForBtn {
    box-shadow: 2px 0px 8px #8888884f;
    border-radius: 20px;
    padding: 30px 30px 30px 30px;
    background-color: white;
    transition: all .3s ease-in-out;
}

@media screen and (min-width:900px){
    .shadowForBtn:hover { 
        transform: scale(1.03);
        color: white !important;
        box-shadow: 0px 0px 15px 1px #0f0f0f;
  }
}

#time {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: transparent;
}
.time_btn {
    width: 100%;
    border: none;
    background-color: transparent;
}
.time_btn:hover {
    background-color: rgba(192, 192, 192, 0.24);
}
.display_out {
    display: none;
}
.paymentMethodsWraper_orphans {
    padding: 10px 0px 10px 0px;
    display: grid;
    grid-template-columns: 45% 45%;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 10px;
}
@media (max-width: 900px) {
    .banner_sponser {
        display: none;
    }
    .spin_orphan_sliders {
        padding: 5px;
    }
    .hey {
        display: none;
    }
    .spin_orphan_sliders .swiper-wrapper{
       margin-left: 15px;
    }
    .orphan_card_border{
        height: 150px;
        width: 130px;
        margin-right: 20px;
    }
    .btn_img_wraper_become_a_spon {
        height: 150px;
        width: 130px;
    }
    .orphan_sliders button {
        height: 200px;
        width: 130px;
    }
    .orphan_sliders label {
        top: 91%;
        font-size: 11px !important;
    }
    .btn_img_label_become_a_spon {
        top: 88%;
        font-size: 11px !important;
    }
    .country_flags_img {
        height: 70px;
        width: 70px;
    }
    .counteies_flags_wraper {
        padding: 5px 15px 5px 15px;
    }
    .counteies_flags_wraper label {
        font-size: 11px;
    }
    
    .width_for_orphans {
        width: 90% !important;
    }
    .text_will_be_remove {
        display: none;
    }
    .disableWhen {
        display: none;
    }
    .years_become_a_sposer_title {
        width: 100%;
    }
    .years_amount_become_a_sposer, .years_become_a_sposer {
        width: 100%;
    }
    .years_amount_become_a_sposer {
        gap: 20px;
    }
    .choosen_method_become_a_sposer {
        display: none;
    }
    .grid_to_display_flex {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .display_out {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .favoritesIcon {
        height: 40px;
        width: 45px;
    }
    .padding_out, .years_become_a_sposer_title label, .aed_mob {
        color: gray;
        font-size: 12px !important;
        padding-left: 0px !important;
    }
    .padding_out{
        transition: all .3s ease-in-out;
    }
    
    .country_sliders {
        padding: 20px 10px 20px 10px;
        
    }
    .first {
        min-height: 383px;
    }
    .second {
        min-height: 216px;
    }
    .mobile_input_to_add_monthly_payment {
        width: 100%;
        display: flex;
        gap: 3px;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1;
    }
    .border_shadow_for_mob {
        padding: 15px;
        box-shadow: -2px 1px 8px #8888888f;
        border-radius: 20px;
    }
    .input_for_orphans {
        border: none;
        width: 50px;
        padding-bottom: 0px;
        color: gray;
    }
    .flex_1 {
        flex: 1;
    }
    .title_to_be_modify {
        border-bottom: none !important;
        width: 100% !important;
        font-size: 15px;
        padding-left: 0px !important;
    }
    #monthly_payment_desktop {
        border: none !important;
    }
    .btns_wraper_all {
        grid-template-columns: 33% 33% 33%
    }
    .years_become_a_sposer {
        border-radius: 10px;
        padding: 10px;
        min-height: 74px;
    }
    .time_btn {
        font-weight: unset;
        font-size: 10px;
    }
}
@media (max-width: 1152px) {
    .check_out_payment_method_btns {
        flex-direction: column;
        gap: 20px;
    }
}
@media (max-width: 290px) {
    .shadowForBtn {
        padding: 20px;
    }
}


.donate-behalf-input {
    border: none;
    width: 70%;
    padding: 10px;
    padding-left: 30px;
    border-radius: 12px;
    box-shadow: 0 0 10px #0e0d0d61;
    margin-bottom: 20px;
    -webkit-appearance: none;
}
@media (max-width: 900px) {
    .donate-behalf-input {
        margin-bottom: unset;
    }
    .termsAndCondText {
        margin-bottom: unset;
    }
}

.behalf_buttons {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 25px;
}
.behalf_donate_div {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
}